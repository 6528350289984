import { useCallback, useMemo } from 'react';

import ReactPlayer from 'react-player';

import styles from './Player.module.scss';

interface PlayerProps {
  videoUrl: string;
  subtitlesUrl?: string;
}

export default function Player({ videoUrl, subtitlesUrl }: PlayerProps) {
  const videoConfig = useMemo(
    () =>
      subtitlesUrl
        ? {
            file: {
              attributes: {
                crossOrigin: 'true',
              },
              tracks: [
                {
                  kind: 'subtitles',
                  src: subtitlesUrl,
                  srcLang: 'en',
                  default: true,
                  label: 'Subtitles',
                },
              ],
            },
          }
        : undefined,
    [subtitlesUrl],
  );

  const removeEmptyCues = useCallback((player: ReactPlayer) => {
    const video = player.getInternalPlayer();

    if (!(video instanceof HTMLVideoElement)) {
      return;
    }

    const subtitleTrack = Array.from(video.textTracks).find(
      (track) => track.kind === 'subtitles',
    );

    const cues = subtitleTrack?.cues;

    if (!cues) {
      return;
    }

    for (let i = 0; i < cues.length; i++) {
      const cue = cues[i];

      if (cue instanceof VTTCue && !cue.text.trim()) {
        subtitleTrack.removeCue(cue);
      }
    }
  }, []);

  return (
    <>
      {!videoUrl && 'Please provide videoUrl param'}
      {videoUrl && !ReactPlayer.canPlay(videoUrl) && 'Video not supported'}
      {videoUrl && ReactPlayer.canPlay(videoUrl) && (
        <ReactPlayer
          className={styles.reactPlayer}
          config={videoConfig}
          controls
          height="100%"
          url={videoUrl}
          width="100%"
          onReady={removeEmptyCues}
        />
      )}
    </>
  );
}
